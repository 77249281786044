<template>
  <vx-card style="padding-left: 10px">
    <p style="margin-bottom: 10px;">
      <strong>Date:</strong>
      {{ invoice.startDate | date_formatter }} -
      {{ invoice.endDate | date_formatter }}
    </p>
    <p style="margin-bottom: 10px;">
      <strong>Doctor:</strong>
      {{ `${invoice.doctorId.firstName} ${invoice.doctorId.lastName}` }}
    </p>

    <p style="margin-bottom: 10px;">
      <strong>Doctor Email:</strong>
      {{ invoice.doctorId.email }}
    </p>
    <p style="margin-bottom: 10px;">
      <strong>Total Number Of Consultations:</strong>
      {{ invoice.paymentData.totalNumberOfConsultations }}
    </p>
    <p style="margin-bottom: 10px;">
      <strong>Total Amount:</strong>
      AUD {{ (invoice.paymentData.totalAmount / 100).toFixed(2) }}
    </p>
    <p style="margin-bottom: 10px;">
      <strong>Service Fee Retained By Fresh Clinics:</strong>
      AUD
      {{
        (invoice.paymentData.servicefeeRetainedByFreshClinics / 100).toFixed(2)
      }}
    </p>
    <p style="margin-bottom: 10px;">
      <strong>GST On Service Fee:</strong>
      AUD {{ (invoice.paymentData.gstOnServiceFee / 100).toFixed(2) }}
    </p>
    <p style="margin-bottom: 10px;">
      <strong>Total Fee Retained By Fresh Clinics:</strong>
      AUD
      {{
        (invoice.paymentData.totalfeeRetainedByFreshClinics / 100).toFixed(2)
      }}
    </p>
    <p style="margin-bottom: 10px;">
      <strong>Net Fee Returned To Doctor:</strong>
      AUD {{ (invoice.paymentData.netFeesReturnedToDoctor / 100).toFixed(2) }}
    </p>
    <p style="margin-bottom: 10px;">
      <strong>GST From Clinics:</strong>
      AUD {{ (invoice.paymentData.gstFromClinics / 100).toFixed(2) }}
    </p>
    <p style="margin-bottom: 10px;">
      <strong>Amount To Be Deposited:</strong>
      AUD {{ (invoice.paymentData.amountToBeDeposited / 100).toFixed(2) }}
    </p>
    <p style="margin-bottom: 10px;">
      <strong>Paid Status:</strong>
      {{ invoice.paidStatus }}
    </p>

    <p
      v-if="invoice.paidStatus === 'Failed' && invoice.reason"
      style="margin-bottom: 10px;"
    >
      <strong>Failed Reason:</strong>
      {{ invoice.reason }}
    </p>

    <p
      v-if="
        !invoice.doctorId.paymentDetails ||
          !invoice.doctorId.paymentDetails.bankAccountId ||
          !invoice.checkIfPaymentCanBeProceeded
      "
    >
      <strong>Note</strong>
    </p>
    <span
      v-if="
        !invoice.doctorId.paymentDetails ||
          !invoice.doctorId.paymentDetails.bankAccountId
      "
      style="display: block; margin-left: 10px;"
      >Doctor has not set up the bank details</span
    >
    <span
      style="display: block; margin-left: 10px;"
      v-if="!invoice.checkIfPaymentCanBeProceeded"
      >Involved clinics payment has not been completed</span
    >

    <h5 style="margin-top: 20px;">Clinics Involved</h5>

    <div class="table-responsive">
      <table class="table table-bordered">
        <thead>
          <tr>
            <th>Clinic Name</th>
            <th>Email</th>
            <th>Clinic Payment Status</th>
            <th>Description</th>
          </tr>
        </thead>

        <tbody>
          <tr v-for="clinic in invoice.clinics" :key="clinic._id">
            <td>{{ clinic.clinicName }}</td>
            <td>{{ clinic.email }}</td>
            <td>{{ clinic.clinicPayment[0].paidStatus }}</td>
            <td>
              {{
                clinic.clinicPayment[0].paidStatus === "Failed" ||
                clinic.clinicPayment[0].paidStatus === "Hold"
                  ? clinic.clinicPayment[0].paymentStatusDescription
                  : "Paid"
              }}
            </td>
          </tr>

          <tr
            v-for="paymentNotInitiatedClinics in invoice.paymentNotInitiatedClinicsArray"
            :key="paymentNotInitiatedClinics._id"
          >
            <td>{{ paymentNotInitiatedClinics.clinicName }}</td>
            <td>{{ paymentNotInitiatedClinics.email }}</td>
            <td>Pending</td>
            <td>Payment not initiated for the clinic</td>
          </tr>
        </tbody>
      </table>
      <!-- <vs-table ref="table">
        <template slot="thead">
          <vs-th>Clinic Name</vs-th>
          <vs-th>Email</vs-th>
          <vs-th>Clinic Payment Status</vs-th>
          <vs-th>Description</vs-th>
        </template>

        <template slot-scope="{ data }">
          <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
            <vs-td :data="data[indextr].clinicName">{{ data[indextr].clinicName | capitalize }}</vs-td>
            <vs-td :data="data[indextr].email">{{ data[indextr].email }}</vs-td>
            <vs-td
              :data="data[indextr].clinicPayment[0].paidStatus"
            >{{ data[indextr].clinicPayment[0].paidStatus }}</vs-td>

            <vs-td>
              {{
              data[indextr].clinicPayment[0].paidStatus === "Failed" ||
              data[indextr].clinicPayment[0].paidStatus === "Hold"
              ? data[indextr].clinicPayment[0].paymentStatusDescription
              : "N/A"
              }}
            </vs-td>
          </vs-tr>
        </template>

        <template
          v-if="invoice.paymentNotInitiatedClinicsArray && invoice.paymentNotInitiatedClinicsArray.length > 0"
        >
          <tr
            v-for="paymentNotInitiatedClinics in invoice.paymentNotInitiatedClinicsArray"
            :key="paymentNotInitiatedClinics._id"
          >
            <td>{{paymentNotInitiatedClinics.clinicName}}</td>
            <td>{{paymentNotInitiatedClinics.email}}</td>
            <td>N/A</td>
            <td>Payment not initiated for the clinic</td>
          </tr>
        </template>
      </vs-table>-->
      <vs-button
        style="margin-top: 20px;"
        :disabled="
          !invoice.doctorId.paymentDetails ||
            !invoice.doctorId.paymentDetails.bankAccountId ||
            !invoice.checkIfPaymentCanBeProceeded
        "
        @click="proceedPayout(invoice._id)"
        >Proceed Payout</vs-button
      >
      <small style="display: block;margin-top: 10px;">
        Note: Doctor should have completed bank detail setup.
      </small>
    </div>
  </vx-card>
</template>

<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      invoice: null,
      loggedInUserType:
        this.$store.state.AppActiveUser.userType === "superAdmin"
          ? "super-admin"
          : this.$store.state.AppActiveUser.userType
    };
  },
  methods: {
    ...mapActions("general", [
      "fetchAdminAggregatedReportDetail",
      "proceedDoctorPayout"
    ]),
    getInvoiceDetail(id) {
      this.$vs.loading();
      let self = this;
      this.fetchAdminAggregatedReportDetail(id)
        .then(res => {
          self.invoice = res.data.data;
          this.$vs.loading.close();
        })
        .catch(err => {
          self.$vs.notify({
            text: err.data.message,
            color: "danger",
            title: "Error"
          });
          this.$vs.loading.close();
        });
    },
    proceedPayout(reportId) {
      const self = this;
      self.$vs.loading();
      self
        .proceedDoctorPayout(reportId)
        .then(response => {
          self.$vs.notify({
            title: "Success",
            text: "Payout successfully proceeded",
            color: "success"
          });
          self.$router.push(
            `/${self.loggedInUserType}/doctor-aggregated-report`
          );

          self.$vs.loading.close();
        })
        .catch(err => {
          self.$vs.notify({
            title: "Error",
            text: err.data.message,
            color: "danger"
          });
          self.$vs.loading.close();
        });
    }
  },
  created() {
    const invoiceId = this.$route.params.invoiceId;
    this.getInvoiceDetail(invoiceId);
  }
};
</script>

<style scoped>
.table-bordered {
  border: 1px solid #dee2e6;
}

.table {
  width: 100%;
  margin-bottom: 1rem;
  background-color: transparent;
}

.table-bordered thead td,
.table-bordered thead th {
  border-bottom-width: 2px;
}

.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
}

.table-bordered td,
.table-bordered th {
  border: 1px solid #dee2e6;
}

.table tbody tr td {
  padding: 6px 10px;
}
</style>
